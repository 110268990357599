import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Box, Flex, Text, Button } from 'rebass/styled-components'
import styled from 'styled-components'
import { Input } from '@rebass/forms/styled-components'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { openConfirmModal } from '../../system/redux/reducers/modal'
import { getAuth } from 'firebase/auth'
import { db } from '../../system/firebase/index'
import {
    updateDoc,
    doc,
    updateProfile,
    updatePassword,
} from '@firebase/firestore'
import { updateUserEmail, updateUserPassword } from '../../models/Users'
import { resetUserPassword } from '../../models/Users'

const StyledForm = styled(Box)`
    input {
        background-color: ${(props) => props.theme.colors.charcoal};
        border-color: ${(props) => props.theme.colors.aquaMedium};
        border-radius: 0;
        border-style: solid;
        border-width: 1px;
        color: ${(props) => props.theme.colors.white};
        font-family: ${(props) => props.theme.fonts.body};
        margin-bottom: ${(props) => props.theme.space.sm};
        outline: none;
        transition-duration: 300ms;
        transition-timing-function: ease-out;
        transition-property: border-color;
        will-change: border-color;

        :focus {
            border-color: ${(props) => props.theme.colors.aquaLight};
        }
        &.error {
            border-color: ${(props) => props.theme.colors.errorLight};
            border-width: 2px;
        }
    }
    .error {
        color: ${(props) => props.theme.colors.errorDark};
    }
`
const ResetLink = styled(Link)`
    color: ${(props) => props.theme.colors.grey};
`

const AccountForm = ({ type, dispatch, user, mismatch }) => {
    const { register, handleSubmit } = useForm()
    const [accountSaved, setAccountSaved] = useState(false)
    const [passwordSaved, setPasswordSaved] = useState(false)
    const [passwordMatch, setPasswordMatch] = useState(true)
    const [cred, setCred] = useState()
    const [verify, setVerify] = useState(false)
    const accountStatus = accountSaved ? 'Updated' : 'Update'
    const passwordStatus = passwordSaved ? 'Updated' : 'Update'
    const [view, setView] = useState('account')
    const [state, updateState] = useState({ email: '', password: '' })

    const [accountInfo, setAccountInfo] = useState({
        first: user.name.first || '',
        last: user.name.last || '',
        email: user.contact.email || '',
    })
    const [newPassword, setNewPassword] = useState({
        password1: '',
        password2: '',
    })
    const handleAccountChange = (e, type) => {
        setAccountSaved(false)
        setAccountInfo({
            ...accountInfo,
            [type]: e.target.value,
        })
    }
    const handlePasswordChange = (e, type) => {
        setPasswordSaved(false)
        setNewPassword({
            ...newPassword,
            [type]: e.target.value,
        })
    }
    const handleVerifyChange = (e) => {
        setCred(e.target.value)
    }
    const accountSubmit = () => {
        setAccountSaved(true)
        if (
            accountInfo.first != user.name.first ||
            accountInfo.last != user.name.last
        ) {
            const uploadData = {
                name: {
                    first: accountInfo.first || user.name.first,
                    last: accountInfo.last || user.name.last,
                },
            }
            const userRef = doc(db, 'users', user.uid)
            updateDoc(userRef, uploadData)
        }
        if (accountInfo.email != user.contact.email && !cred) {
            setAccountSaved(false)
            setVerify(true)
        } else if (accountInfo.email != user.contact.email && cred) {
            updateUserEmail(dispatch, accountInfo.email, cred)
            if (mismatch) {
                setVerify(true)
                setAccountSaved(false)
            } else {
                setVerify(false)
                setAccountSaved(true)
            }
        }
    }
    const passwordSubmit = () => {
        if (newPassword.password1 === newPassword.password2) {
            setPasswordSaved(true)
            setPasswordMatch(true)
            updateUserPassword(dispatch, cred, newPassword.password1)
            setCred(null)
        } else {
            setPasswordSaved(false)
            setPasswordMatch(false)
        }
    }
    const logout = (e, type) => {
        e.preventDefault()
        // as in, what are we confirming?
        const confirm = { confirm: 'logout' }
        dispatch(openConfirmModal(confirm))
    }
    const onInputChange = (name, event) => {
        const change = { ...state }
        change[name] = event.target.value
        updateState(change)
    }
    const handleClick = (e, type) => {
        e.preventDefault()
        if (type === 'sent') {
            console.log('resetting password')
            resetUserPassword(state.email)
        }
        setView(type)
    }

    let output = null
    switch (view) {
        case 'account':
            output = (
                <>
                    <Flex
                        bg='charcoal'
                        mt={['md', 'md', '110px']}
                        justifyContent='center'
                    >
                        <Flex
                            width='85%'
                            flexDirection={[
                                'column',
                                'column',
                                'column',
                                'column',
                                'row',
                            ]}
                        >
                            <StyledForm
                                width={['auto', 'auto', 'auto', 'auto', '100%']}
                                pl={['0px', '0px', '0px', '0px', '0px', 'lg']}
                                ml={['sm', 'sm', 'sm', 'sm', 'sm', '0px']}
                                my='sm'
                                mr={['sm', 'sm', 'sm', 'sm', 'md']}
                                as='form'
                                onSubmit={handleSubmit((data) => {
                                    accountSubmit(data)
                                })}
                                sx={{
                                    borderLeft:
                                        '1px solid rgba(255,255,255,0.1)',
                                    '@media screen and (max-width: 500px)': {
                                        border: '0px',
                                    },
                                }}
                            >
                                <Text mb='md' variant='h2'>
                                    ACCOUNT INFORMATION
                                </Text>
                                <Flex
                                    flexDirection={[
                                        'column',
                                        'column',
                                        'column',
                                        'row',
                                    ]}
                                >
                                    <Input
                                        {...register('first')}
                                        onChange={(e) =>
                                            handleAccountChange(e, 'first')
                                        }
                                        autoComplete='firstName'
                                        enterKeyHint='next'
                                        type='text'
                                        mr='sm'
                                        name='firstName'
                                        defaultValue={user.name.first || null}
                                    />
                                    <Input
                                        {...register('last')}
                                        onChange={(e) =>
                                            handleAccountChange(e, 'last')
                                        }
                                        autoComplete='lastName'
                                        enterKeyHint='next'
                                        type='text'
                                        name='lastName'
                                        defaultValue={user.name.last || null}
                                    />
                                </Flex>
                                <Input
                                    {...register('email')}
                                    onChange={(e) =>
                                        handleAccountChange(e, 'email')
                                    }
                                    autoComplete='email'
                                    enterKeyHint='next'
                                    id='form-email'
                                    type='email'
                                    name='email'
                                    defaultValue={user.contact.email || null}
                                />
                                {verify ? (
                                    <>
                                        <Input
                                            {...register('confirm')}
                                            onChange={(e) =>
                                                handleVerifyChange(e)
                                            }
                                            autoComplete=''
                                            enterKeyHint='next'
                                            type='password'
                                            name='confirm'
                                            placeholder='Confirm password'
                                        />
                                        {mismatch ? (
                                            <Text color='red' mb='sm'>
                                                Incorrect password
                                            </Text>
                                        ) : (
                                            <Text mb='sm' color='grey'>
                                                Please confirm your password
                                            </Text>
                                        )}
                                    </>
                                ) : null}
                                <Flex
                                    mt='md'
                                    flexDirection={[
                                        'column',
                                        'column',
                                        'column',
                                        'column',
                                        'row',
                                    ]}
                                >
                                    <Button>{accountStatus}</Button>
                                    <Button
                                        onClick={logout}
                                        aria-pressed='false'
                                        mx={[
                                            '0px',
                                            '0px',
                                            '0px',
                                            '0px',
                                            '0px',
                                            'sm',
                                        ]}
                                        mt={[
                                            'sm',
                                            'sm',
                                            'sm',
                                            'sm',
                                            'sm',
                                            '0px',
                                        ]}
                                        variant='primary'
                                    >
                                        Logout
                                    </Button>
                                </Flex>
                            </StyledForm>
                            <StyledForm
                                pl={['0px', '0px', '0px', '0px', '0px', 'lg']}
                                width={['auto', 'auto', '100%']}
                                m='sm'
                                as='form'
                                onSubmit={handleSubmit((data) => {
                                    passwordSubmit(data)
                                })}
                                sx={{
                                    borderLeft:
                                        '1px solid rgba(255,255,255,0.1)',
                                    '@media screen and (max-width: 500px)': {
                                        border: '0px',
                                    },
                                }}
                            >
                                <Text mb='md' variant='h2'>
                                    CHANGE YOUR PASSWORD
                                </Text>
                                <Input
                                    {...register('currentpass')}
                                    onChange={(e) => handleVerifyChange(e)}
                                    enterKeyHint='next'
                                    type='password'
                                    name='currentpass'
                                    placeholder='Current password'
                                />
                                {newPassword.password1 &&
                                newPassword.password2 &&
                                mismatch ? (
                                    <Text color='red' mb='sm'>
                                        Incorrect password
                                    </Text>
                                ) : null}
                                <Input
                                    {...register('password1')}
                                    onChange={(e) =>
                                        handlePasswordChange(e, 'password1')
                                    }
                                    type='password'
                                    placeholder='New password'
                                />
                                <Input
                                    {...register('password2')}
                                    onChange={(e) =>
                                        handlePasswordChange(e, 'password2')
                                    }
                                    type='password'
                                    placeholder='Retype new password'
                                />
                                {!passwordMatch ? (
                                    <Text mb='sm' color='red'>
                                        {' '}
                                        Passwords do not match
                                    </Text>
                                ) : null}
                                <Flex
                                    mt='md'
                                    flexDirection={[
                                        'column',
                                        'column',
                                        'column',
                                        'column',
                                        'row',
                                    ]}
                                >
                                    <Button
                                        aria-pressed='false'
                                        enterKeyHint='done'
                                        type='submit'
                                        variant='primary'
                                        mr='sm'
                                        width={[
                                            '100%',
                                            '100%',
                                            '100%',
                                            '100%',
                                            'auto',
                                        ]}
                                    >
                                        {passwordStatus}
                                    </Button>
                                    <Box alignSelf='center' mt='xs'>
                                        <ResetLink
                                            to='#'
                                            onClick={() => setView('forgotten')}
                                        >
                                            Forgot your password?
                                        </ResetLink>
                                    </Box>
                                </Flex>
                            </StyledForm>
                        </Flex>
                    </Flex>
                </>
            )
            break
        case 'forgotten':
            output = (
                <>
                    <StyledForm
                        as='form'
                        maxWidth={['310px', '470px', '543px']}
                        margin={['sm', 'sm', 'sm', 'sm', 'auto']}
                    >
                        <Box
                            pb='xs'
                            mb='md'
                            sx={{
                                borderBottom: '1px solid rgba(255,255,255,0.1)',
                            }}
                        >
                            <Text as='h2' variant='h2'>
                                PASSWORD RESET EMAIL
                            </Text>
                        </Box>
                        <Text as='p' variant='body' mb='sm'>
                            Please enter the email address associated with the
                            account and if that address is registered with us,
                            you will recieve an email to reset your password
                        </Text>
                        <Input
                            autoComplete='off'
                            enterKeyHint='next'
                            id='form-email'
                            type='email'
                            name='email'
                            defaultValue='Email address'
                            onChange={(e) => onInputChange('email', e)}
                        />
                        <Flex justifyContent='space-between'>
                            <Link to='/'>
                                <Button
                                    aria-pressed='false'
                                    enterKeyHint='done'
                                    type='submit'
                                    variant='primary'
                                    onClick={(e) => handleClick(e, 'sent')}
                                >
                                    Send email
                                </Button>
                            </Link>
                            <Box ml='sm' alignSelf='flex-end'>
                                <ResetLink
                                    to='/'
                                    onClick={(e) => handleClick(e, 'account')}
                                >
                                    Cancel
                                </ResetLink>
                            </Box>
                        </Flex>
                    </StyledForm>
                </>
            )
            return
        case 'sent':
            output = (
                <>
                    <StyledForm
                        as='form'
                        maxWidth={['310px', '470px', '543px']}
                        margin={['sm', 'sm', 'sm', 'sm', 'auto']}
                    >
                        <Box
                            pb='xs'
                            mb='md'
                            sx={{
                                borderBottom: '1px solid rgba(255,255,255,0.1)',
                            }}
                        >
                            <Text as='h2' variant='h2'>
                                Thanks
                            </Text>
                        </Box>
                        <Text as='p' variant='body' mb='sm'>
                            If an email address is associated with your account,
                            you will receive an email notification with
                            instructions to reset your password.
                        </Text>
                    </StyledForm>
                </>
            )
            break
        default:
        //
    }
    return <>{output}</>
}

function mapStateToProps(state) {
    return {
        user: state.Auth.user,
        mismatch: state.Auth.verificationMismatch,
    }
}

export default connect(mapStateToProps)(AccountForm)
